import React, { useEffect, useState } from 'react';
import { ReactSession } from 'react-client-session';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
ReactSession.setStoreType("localStorage");

 function Login() {
    const [input, setInput] = useState({});
    const [error, setError]=useState({});
    const [formsubmit, setsubmit]=useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    
    const queryParameters = new URLSearchParams(window.location.search)
    const forgot = queryParameters.get("id");
    
    const [fEmail, setFEmal] = useState('');
    const [fWhatsApp, setFWhatsApp] = useState('');
    const [fType, setFType] = useState('');
    const [fOtp, setFOtp] = useState('');
    const [fpass1, setFPass1] = useState('');
    const [fpass2, setFPass2] = useState('');
    const [forgotStarts, setForgots] = useState(null);

    if(!ReactSession.get("arkuserId")){
      ReactSession.set("arkuserId","0");
    }
    else{
      ReactSession.set("arkuserId", ReactSession.get("arkuserId"));
    }

   

    const handleChange=(event)=>{
        const name = event.target.name;
        const value =event.target.value;
        setInput(values => ({...values, [name]: value}))
    }

    const handleSubmit =async(event)=>{
        event.preventDefault();
        setsubmit(true);
  
      try {
        const response = await axios.post('https://api.arivomkadai.com/logincustomer/', input);
        
        if(response.data.status === 'success' ){
            //console.log(response.data);
          
          ReactSession.set("arkuserId", response.data.data[0].user_id);
        ReactSession.set("userName", response.data.data[0].name);
        ReactSession.set("email", response.data.data[0].email);
        ReactSession.set("phonenumber", response.data.data[0].phonenumber);
        ReactSession.set("gender", response.data.data[0].gender);
        ReactSession.set("profile", response.data.data[0].profile);
        ReactSession.set("building", response.data.data[0].buiulding);
        ReactSession.set("street", response.data.data[0].street);
        ReactSession.set("city", response.data.data[0].city);
        ReactSession.set("state", response.data.data[0].state);
        ReactSession.set("pincode", response.data.data[0].pincode);
      
         alert(`Welcome ${response.data.data[0].name}`);
          
         navigate('/offline');
        }else{
          //console.log(response.data.error.message);
          ReactSession.set("arkuserId", "0");
          const errors ='Username or Password is incorrect'
          setMessage(errors);
        }
  
        setError(validate(response));
        
        } catch (error) {
          console.error('Login failed!', error);
          ReactSession.set("arkuserId", "0");
        }
  
    }

    const validate=(response)=>{
        const errors={};
          if(response.data.error){
            errors.name = response.data.error.message;
          }
          return errors;
      }
    
      useEffect(()=>{
        //console.log(error);
        if(Object.keys(error).length ===0 & formsubmit){
          
          //console.log(input);
        }
       
      });
      
     
  const forgotStart = () => {
    openModal();
  };

  const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
};

const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
};

const openfirst = () => {
  const first = document.getElementById('first');
  const second = document.getElementById('second');
  const third = document.getElementById('third');
  first.classList.remove('hide');
  second.classList.add('hide');
  third.classList.add('hide');
};

const openSec = () => {
  const first = document.getElementById('first');
  const second = document.getElementById('second');
  first.classList.add('hide');
  second.classList.remove('hide');
};

const openthird = () => {
  const second = document.getElementById('second');
  const third = document.getElementById('third');
  second.classList.add('hide');
  third.classList.remove('hide');
};

const checkInput = async (e) => {
  e.preventDefault();
  setError('');
  const sendData = {email : fEmail, number : fWhatsApp, type: fType}
  try{
    const response = await axios.post('https://api.arivomkadai.com/forgot-password/', sendData);
    console.log(response.data);
    if(response.data.status === 'success'){
      if(response.data.message === 'accepted'){
         setFEmal(response.data.email);
         openSec();
      }else{
        setError('Unable to send OTP, please try again!');
      }
    }else{
      setError('We cannot find your account, please try again with the Email or WhatsApp number registered with us!');

    }
  }catch (error) {
    console.error(error);
  }
};

const checkOtp = async (e) => {
  setError('');
  e.preventDefault();
  const otpData = {email : fEmail, otp: fOtp}
  try{
    const response = await axios.post('https://api.arivomkadai.com/verify/', otpData);
    console.log(response.data);
    if(response.data.status === 'success'){
      if(response.data.otp === 'yes'){
        openthird();
      }else{
       setError('Invalid OTP, please try again!');
      }
    }else{
      setError('Unable to process your request, please try again!');
    }
  }catch (error) {
    console.error(error);
  }

};

const changePass = async (e) => {
  setError('');
  e.preventDefault();
  if(fpass1 === fpass2){
    const passData = {email : fEmail, pass1: fpass1, pass2: fpass2}
    try{
      const response = await axios.post('https://api.arivomkadai.com/change-password/', passData);
      console.log(response.data);
      if(response.data.status === 'success'){
       alert('Password updated. Please login with your new Password!');
       closeModal();
       openfirst();
       setFEmal('');
       setFWhatsApp('');
       setFOtp('');
       setFPass1('');
       setFPass2('');
      }else{
        setError('Unable to process your request, please try again!');
      }
    }catch (error) {
      console.error(error);
    }
  }else{
    setError('Passwords are not matching!');
  }
};
useEffect(() =>{
if(forgotStarts === null){
  setForgots(forgot);
}

if(forgotStarts === 'forgot'){
  forgotStart();
  setForgots('done');
}

},[forgotStarts]);
return (
  <>
   <main class="main pages" >

   <div class="modal fade custom-modal" id="onloadModal" tabindex="-1" aria-labelledby="onloadModalLabel" aria-hidden="true">
    <div class="modal-dialog">
        <div class="modal-content">
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
            <div class="modal-body">
                <div class="login_wrap widget-taber-content background-white">
                    <div class="padding_eight_all bg-white" id="first">
                        <div class="heading_s1">
                            <h4 class="mb-5">Forgot Password ?</h4>
                            <p class="mb-20">Enter your Email ID OR WhatsApp Number registered with us.</p>
                            <h2 class="mb-30">01<span style={{fontSize: '18px'}}>/03</span></h2>
                        </div>
                        <form onSubmit={checkInput}>
                            <div class="row">
                              <div class="m-auto col-lg-8 col-md-8">
                                <div class="row">
                                <div class="col-lg-5 col-md-5">
                                    <div class="form-group">
                                        <input type="email"  placeholder="Enter your Email ID..." value={fEmail} onChange={(e) => {setFEmal(e.target.value); setFWhatsApp(''); setFType('email');}} />
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-2 mt-3" align="center">
                                  <p>OR</p>
                                </div>
                                <div class="col-lg-5 col-md-5">
                                    <div class="form-group">
                                        <input type="number"  placeholder="Enter your WhatsApp Number..." value={fWhatsApp} onChange={(e) => {setFWhatsApp(e.target.value); setFEmal(''); setFType('whatsapp');}} />
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>

                            <div class="form-group" align="center">
                                <button type="submit" class="btn btn-heading btn-block hover-up mt-4" name="login">Continue</button>
                            </div>
                        </form>
                    </div>

                    <div class="padding_eight_all bg-white hide" id="second">
                        <div class="heading_s1">
                            <h4 class="mb-5">Forgot Password ?</h4>
                            <p class="mb-20">Enter OTP shared to your {fType === 'email'? `Email ID ${fEmail}`:`WhatsApp Number ${fWhatsApp}`}.</p>
                            <h2 class="mb-30">02<span style={{fontSize: '18px'}}>/03</span></h2>
                        </div>
                        <form onSubmit={checkOtp}>
                            <div class="row">
                              <div class="m-auto col-lg-5 col-md-5">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="number"  placeholder="Enter OTP..." value={fOtp} onChange={(e) => {setFOtp(e.target.value);}} />
                                    </div>
                                </div>
                              </div>
                            </div>

                            <div class="form-group" align="center">
                                <button type="submit" class="btn btn-heading btn-block hover-up mt-4" name="login">Continue</button>
                            </div>
                        </form>
                    </div>

                    <div class="padding_eight_all bg-white hide" id="third">
                        <div class="heading_s1">
                            <h4 class="mb-5">Forgot Password ?</h4>
                            <p class="mb-20">Enter new password.</p>
                            <h2 class="mb-30">03<span style={{fontSize: '18px'}}>/03</span></h2>
                        </div>
                        <form onSubmit={changePass}>
                            <div class="row">
                              <div class="m-auto col-lg-8 col-md-8">
                              <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="password"  placeholder="Enter Password..." value={fpass1} onChange={(e) => {setFPass1(e.target.value);}} />
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="password"  placeholder="Re-Enter Password..." value={fpass2} onChange={(e) => {setFPass2(e.target.value);}} />
                                    </div>
                                </div>
                              </div>
                              </div>
                            </div>

                            <div class="form-group" align="center">
                                <button type="submit" class="btn btn-heading btn-block hover-up mt-4" name="login">Continue</button>
                            </div>
                        </form>
                    </div>
                    <div align="center">
                      <p class="text-danger">{error.length > 0 && error}</p>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
       
        <div class="page-content pt-100 pb-150" style={{maxHeight: "100vh"}}>
            <div class="container">
                <div class="row">
                    <div class="col-xl-8 col-lg-10 col-md-12 m-auto">
                        <div class="row">
                            <div class="col-lg-6 pr-30 d-none d-lg-block">
                                <img class="border-radius-15" src="assets/imgs/page/login-1.png" alt="" />
                            </div>
                            <div class="col-lg-6 col-md-8 pt-50">
                                <div class="login_wrap widget-taber-content background-white">
                                    <div class="padding_eight_all bg-white">
                                        <div class="heading_s1">
                                            <h1 class="mb-5">Login</h1>
                                            <p class="mb-30">Don't have an account? <a href='/register'>Create here</a></p>
                                        </div>
                                        <form onSubmit={handleSubmit}>
                                        <div class="form-group">
                                                <input type="email" required name="email" placeholder="Enter Email" value={input.email} onChange={handleChange}/>
                                            </div>
                                            <div class="form-group">
                                                <input required type="password" name="password" placeholder="Enter Password"  value={input.password} onChange={handleChange}/>
                                            </div>
                                            {message && message.length > 0 && (
                                              <p className="text-danger">{message}</p>
                                            )}
                                           
                                            <div class="form-group">
                                                <button type="submit" class="btn btn-heading btn-block hover-up" name="login">Log in</button>
                                                <a onClick={forgotStart} class="pl-20">Forgot password?</a>
                                            </div>
                                           
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
  </>
  )
}
export default Login;